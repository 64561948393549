#menuIcon {
    border-radius: 50%;
    padding: 20px;
    background-color: transparent;
    width: 60px;
    height: 60px;
    justify-content: center;
    display: flex;
    position: absolute;
    right: 20px;
    top: 20px;
    z-index: 100;
}

.menuIcon {
    color: white;
    font-size: 50px;
    align-self: center;
}

#navbar {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    background-color: rgba(0, 0, 0, 0.9);
    display: flex;
    flex-direction: row;
    justify-content: center;
    overflow: hidden;
}

div.nav-container {
    flex-direction: row;
    align-items: flex-start;
    margin-top: 50px;
}

div.link-group {
    display: flex;
    flex-direction: column;
    padding: 20px 40px;
}

#navbar a {
    color: white;
    text-decoration: none;
    font-size: 1.3rem;
    font-weight: 900;
    padding: 50px 8px 8px 8px;
}

#navbar a.subLink {
    font-size: 1rem;
    font-weight: 300;
    padding: 5px;
}

#navbar a.active {
    color: #fbbc09;
}

@media only screen and (max-width: 768px) {
    #navbar {
        justify-content: flex-start;
        padding: 60px;
    }
    div.nav-container {
        flex-direction: column;
    }
    div.link-group {
        padding: 5px;
    }
}

@media only screen and (max-width: 480px) {
    #navbar {
        position: absolute;
        top: 0;
        bottom: 0;
        right: 0;
        left: 0;
        flex-direction: column;
        justify-content: flex-start;
    }
    div.nav-container {
        text-align: left;
    }
}
