/* COMMON MEDIA QUERIES
320px — 480px: Mobile devices.
481px — 768px: iPads, Tablets.
769px — 1024px: Small screens, laptops.
1025px — 1200px: Desktops, large screens.
1201px and more — Extra large screens, TV. */

.App {
    flex-direction: column;
}

.side-margin {
    display: flex;
    max-width: 1200px;
    margin: 0 auto;
    flex-direction: column;
    align-items: center;
    padding: 0 10%;
}

html {
    font-size: 16px;
}

body {
    margin: 0;
    padding: 0;
}

div.main {
    flex-direction: column;
    justify-content: center;
    max-width: 550px;
    width: 100%;
    text-align: center;
    align-items: center;
}

div.header-text-margin {
    margin-top: 200px;
}

div.header-text-margin-half {
    margin-top: 100px;
}

div.container {
    margin-left: auto;
    margin-right: auto;
    max-width: 800px;
    position: relative;
    padding: 40px 20px 20px 20px;
    border-top-right-radius: 50px;
    border-top-left-radius: 50px;
}

p,
ul,
a {
    color: black;
    font-family: "Playfair Display", sans-serif;
    text-align: left;
    font-size: 1.2rem;
    line-height: 1.4;
    font-weight: 300;
    width: 100%;
}

p.italic {
    font-style: italic;
}

span.red {
    color: red;
    text-shadow: -1px 2px 1px #d3d3d3;
}

span.stroke {
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 4px black;
}

span.outlined {
    -webkit-text-fill-color: white;
    -webkit-text-stroke: 0.7px black;
    font-size: 2.1rem;
}

span.font-hertz {
    font-family: "Playfair Display", "Lato", serif;
}

span.stroke-white {
    -webkit-text-fill-color: black;
    -webkit-text-stroke: 1px white;
}

.flex {
    display: -webkit-box; /* OLD - iOS 6-, Safari 3.1-6 */
    display: -moz-box; /* OLD - Firefox 19- (buggy but mostly works) */
    display: -ms-flexbox; /* TWEENER - IE 10 */
    display: -webkit-flex; /* NEW - Chrome */
    display: flex; /* NEW, Spec - Opera 12.1, Firefox 20+ */
}

img {
    margin: 20px auto;
    width: 100%;
}

/*All fonts*/
h1,
h2 {
    font-family: "Playfair Display", "Lato", serif;
    font-weight: 900;
    text-align: center;
    font-size: 2.9rem;
}

h1 {
    max-width: 500px;
}

h2 {
    margin: 0 0 20px 0;
}

h3,
h4 {
    font-family: "Playfair Display", sans-serif;
    font-size: 1.5rem;
    font-weight: 900;
    margin-bottom: 0;
}

h4 {
    font-weight: 300;
}

a {
    color: #3f8f82;
}

a:focus {
    border: none;
    outline: none;
}

a.buttonLink {
    padding: 17px 40px;
    border-radius: 10px;
    text-align: center;
    color: white;
    text-decoration: none;
    letter-spacing: 1.5px;
    font-size: 1.2rem;
    font-weight: 300;
    background-color: #faae4e;
    max-width: 180px;
}

/* All colors */
.pink {
    background-color: #ef857d;
}

.yellow {
    background-color: #faae4e;
}

.blue {
    background-color: #80cac2;
}

.black {
    background-color: #202324;
}

.light-grey {
    background-color: #eaeced;
}

.dark-blue {
    background-color: #0c232d;
}

@media only screen and (max-width: 480px) {
    h1,
    h2 {
        font-size: 2.5rem;
    }
}

@-ms-viewport {
    width: device-width;
}
