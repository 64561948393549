.media-images,
.panel-text-tabs {
    flex-wrap: wrap;
    justify-content: center;
}

.media-images img {
    width: 300px;
    height: 100%;
    margin: 10px;
    border-radius: 15px;
}
