div.footer {
    display: flex;
    max-width: 600px;
    flex-direction: column;
    align-items: center;
    margin-left: auto;
    margin-right: auto;
    padding: 30px 0 60px 0;
}

.footer-container {
    padding: 0 20px;
    background-color: black;
}

.footer p {
    font-size: 0.9rem;
    color: white;
    text-align: center;
    line-height: 1.5;
}

.footer a {
    font-size: 0.9rem;
}

.footer a img {
    width: 55px;
    padding-left: 5px;
    padding-right: 5px;
}

.footer .partner-logo a {
    padding: 0 8px;
    color: transparent;
}

.footer .partner-logo a img {
    margin: 0 auto;
    width: 100px;
}

h4.footer-header {
    color: white;
}

.footer img.contact-logo {
    margin: 0 auto;
}
