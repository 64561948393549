.persons {
    flex-direction: column;
    align-items: center;
}

.persons img {
    max-width: 657px;
    border-radius: 10px;
}


