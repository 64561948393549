.panels {
    display: flex;
}

div.panel-item {
    display: flex;
    flex-direction: column;
    padding: 30px 0;
    align-items: center;
    margin: 10px;
    width: 400px;
    border-radius: 10px;
}

div.panel-item img {
    width: 120px;
    margin: 0;
}

div.panel-info {
    color: white;
    text-align: center;
    flex-direction: column;
    align-items: flex-start;
    padding: 25px 35px;
}

div.panel-info h2 {
    margin: 0 auto;
    font-size: 2rem;
}

div.panel-info a {
    color: white;
}

div.panel-info p {
    color: white;
    font-size: 1.1rem;
    line-height: 1.4;
}

div.panel-info a {
    font-size: 1.1rem;
    line-height: 1.4;
}

@media only screen and (max-width: 1024px) {
    .panels {
        flex-direction: column;
    }
    div.panel-item {
        width: unset;
    }
}

@media only screen and (max-width: 768px) {
    div.panel-item {
        width: 100%;
        margin: 10px 0;
    }
    div.panel-item img {
        width: 100px;
    }
}

@media only screen and (max-width: 480px) {
    div.panel-info h2 {
        font-size: 1.7rem;
    }
}
