.privacy-image-container {
    background-color: white;
    padding: 5% 4% 3% 4%;
    margin: 0 auto;
    width: 50%;
}

img.privacy-image {
    width: 100%;
}

@media only screen and (max-width: 480px) {
    .privacy-image-container {
        width: unset;
    }
    img.privacy-image {
        width: 100%;
    }
}
