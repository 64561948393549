.usp {
    display: grid;
    grid-gap: 50px 10px;
    grid-template-columns: auto 1fr;
    padding: 0;
    text-align: start;
}

.usp .check {
    display: flex;
    width: 75px;
    height: 75px;
    border-radius: 100%;
    align-self: center;
    justify-content: center;
    align-items: center;
    margin: 0 20px;
    color: white;
}

.usp h4 {
    font-weight: 900;
    margin: 0 0 10px 0;
}

.usp p {
    margin: 0;
}

@media only screen and (max-width: 480px) {
    .usp {
        display: flex;
        flex-direction: column;
        gap: 10px;
        grid-template-columns: 1fr;
        text-align: start;
    }
    .usp .info {
        text-align: center;
    }
    .usp .check {
        width: 60px;
        height: 60px;
        margin-top: 15px;
    }
    .usp h4 {
        margin-top: 20px;
        margin-bottom: 10px;
    }
}
