div.main-header {
    background: #000 url(./images/1.png) center 0 no-repeat;
}

div.team-header {
    background: #000 url(./images/11.png) center 0 no-repeat;
}

div.sos-header {
    background: #000 url(./images/sos-header.png) center 0 no-repeat;
}

div.media-header {
    background: #000 url(./images/2.png) center 0 no-repeat;
}

div.privacy-header {
    background: #000 url(./images/5.png) center 0 no-repeat;
}

div.outer-header {
    display: flex;
    justify-content: center;
    background-color: black;
}

div.header {
    background-attachment: fixed;
    background-position: center top;
    overflow: hidden;
    align-items: center;
    justify-content: center;
    padding-top: 30px;
}

div.header-separator {
    width: 80px;
    border-top: 1px solid white;
}

div.headers {
    flex-direction: column;
    align-items: center;
    padding: 0 20px;
}

div.header h1 {
    color: white;
    font-family: "Playfair Display", "Lato", sans-serif;
    font-weight: 900;
    font-size: 3rem;
    margin: 20px 0 30px 0;
}

div.header h2 {
    color: white;
    margin: 20px 0;
}

img.logo {
    width: 160px;
    padding-bottom: 20px;
}

@media only screen and (max-width: 480px) {
    div.header h1 {
        font-size: 2.5rem;
    }
    div.header h2 {
        font-size: 2rem;
    }
    img.logo {
        width: 120px;
        padding-bottom: 20px;
    }
}
